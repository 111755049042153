import { FileSource, GetFileExtension, TransformFileHostUrls } from '../engine/io/fileutils.js';
import { ImportErrorCode, ImportSettings } from '../engine/import/importer.js';
import { Viewer } from '../engine/viewer/viewer.js';
import { AddDiv, AddDomElement, ShowDomElement, SetDomElementOuterHeight } from '../engine/viewer/domutils.js';
import { CalculatePopupPositionToScreen, ShowListPopup } from './dialogs.js';
import { HandleEvent } from './eventhandler.js';
import { HashHandler } from './hashhandler.js';
import { Navigator, Selection, SelectionType } from './navigator.js';
import { Settings, Theme } from './settings.js';
import { Sidebar } from './sidebar.js';
import { ThemeHandler } from './themehandler.js';
import { ThreeModelLoaderUI } from './threemodelloaderui.js';
import { Toolbar } from './toolbar.js';
import { ShowExportDialog } from './exportdialog.js';
import { ShowSnapshotDialog } from './snapshotdialog.js';
import { AddSmallWidthChangeEventListener, GetFilesFromDataTransfer, IsSmallWidth,EscapeJquery } from './utils.js';
import { ShowOpenUrlDialog } from './openurldialog.js';
import { ShowSharingDialog } from './sharingdialog.js';
import { HasDefaultMaterial, ReplaceDefaultMaterialColor } from '../engine/model/modelutils.js';
import { Direction } from '../engine/geometry/geometry.js';
import { CookieGetBoolVal, CookieSetBoolVal } from './cookiehandler.js';
import { ShadingType } from '../engine/threejs/threeutils.js';
import { MeasureTool } from './measuretool.js';
import { CloseAllDialogs,ProgressDialog,ButtonDialog,ShowMessageDialog} from './dialog.js';
import axios from 'axios';


import { AddSvgIconElement } from './utils.js';

export const WebsiteUIState =
{
    Undefined : 0,
    Intro : 1,
    Model : 2,
    Loading : 3
};
const ApiPath = "https://www.pn3d.cn:8072";
// const ApiPath = "https://localhost:44334";
const WebPath = "http://s3d.pn3d.cn";
const PDFPath = "http://s3d.pn3d.cn/PDF/web/viewer.html?file=";
//计划
let PlanModel = {
    id:"",
    name:"",
    patientName:"",
    patientCode:""
}
export class Website
{
    constructor (parameters)
    {
        this.parameters = parameters;
        this.settings = new Settings ();
        this.viewer = new Viewer ();
        this.measureTool = new MeasureTool (this.viewer, this.settings);
        this.hashHandler = new HashHandler ();
        this.toolbar = new Toolbar (this.parameters.toolbarDiv);
        this.navigator = new Navigator (this.parameters.navigatorDiv, this.parameters.navigatorSplitterDiv);
        this.sidebar = new Sidebar (this.parameters.sidebarDiv, this.parameters.sidebarSplitterDiv, this.settings);
        this.modelLoaderUI = new ThreeModelLoaderUI ();
        this.themeHandler = new ThemeHandler ();
        this.highlightColor = new THREE.Color (0x8ec9f0);
        this.uiState = WebsiteUIState.Undefined;
        this.model = null;
    }

    Load ()
    {
        this.settings.LoadFromCookies ();
        this.SwitchTheme (this.settings.themeId, false);
        HandleEvent ('theme_on_load', this.settings.themeId === Theme.Light ? 'light' : 'dark');

        this.InitViewer ();
        this.InitToolbar ();
        this.InitDragAndDrop ();
        this.InitSidebar ();
        this.InitNavigator ();
        this.InitCookieConsent ();

        this.viewer.SetMouseClickHandler (this.OnModelClicked.bind (this));
        this.viewer.SetMouseMoveHandler (this.OnModelMouseMoved.bind (this));
        this.viewer.SetContextMenuHandler (this.OnModelContextMenu.bind (this));

        this.Resize ();
        this.SetUIState (WebsiteUIState.Intro);

        this.hashHandler.SetEventListener (this.OnHashChange.bind (this));
        this.OnHashChange ();
        this.InitWantShowFile();

        //菜单显示
        this.TabMenu();

        this.PatientList();
        AddSmallWidthChangeEventListener (() => {
            this.OnSmallWidthChanged ();
        });

        window.addEventListener ('resize', () => {
			this.Resize ();
		});
          //手机端显示
    }

    Resize ()
    {

        let windowWidth = window.innerWidth;
        let windowHeight = window.innerHeight;
        let headerHeight = this.parameters.headerDiv.offsetHeight;

        let navigatorWidth = 0;
        let sidebarWidth = 0;
        let safetyMargin = 0;
        if (!IsSmallWidth ()) {
            navigatorWidth = this.navigator.GetWidth ();
            sidebarWidth = this.sidebar.GetWidth ();
            safetyMargin = 1;
        }

        const minContentWidth = 50;
        let contentWidth = windowWidth - navigatorWidth - sidebarWidth;
        if (contentWidth < minContentWidth) {
            this.sidebar.DecreaseWidth (minContentWidth - contentWidth);
            contentWidth = minContentWidth;
        }
        let contentHeight = windowHeight - headerHeight;

        SetDomElementOuterHeight (this.parameters.introDiv, contentHeight);
        this.navigator.Resize (contentHeight);
        this.sidebar.Resize (contentHeight);
        this.viewer.Resize (contentWidth - safetyMargin, contentHeight);
        this.measureTool.Resize ();
    }

    OnSmallWidthChanged ()
    {

        if (this.uiState === WebsiteUIState.Model) {
            this.UpdatePanelsVisibility ();
        }
    }

    HasLoadedModel ()
    {
        return this.model !== null;
    }

    SetUIState (uiState)
    {
        function ShowOnlyOnModelElements (show)
        {
            let root = document.querySelector (':root');
            root.style.setProperty ('--ov_only_on_model_display', show ? 'inherit' : 'none');
        }

        if (this.uiState === uiState) {
            return;
        }

        this.uiState = uiState;
        if (this.uiState === WebsiteUIState.Intro) {
            ShowDomElement (this.parameters.introDiv, true);
            ShowDomElement (this.parameters.mainDiv, false);
            ShowOnlyOnModelElements (false);
        } else if (this.uiState === WebsiteUIState.Model) {
            ShowDomElement (this.parameters.introDiv, false);
            ShowDomElement (this.parameters.mainDiv, true);
            ShowOnlyOnModelElements (true);
            this.UpdatePanelsVisibility ();
        } else if (this.uiState === WebsiteUIState.Loading) {
            ShowDomElement (this.parameters.introDiv, false);
            ShowDomElement (this.parameters.mainDiv, false);
            ShowOnlyOnModelElements (false);
        }

        this.Resize ();
    }

    ClearModel ()
    {
        CloseAllDialogs ();

        this.model = null;
        this.viewer.Clear ();

        this.parameters.fileNameDiv.innerHTML = '';

        this.navigator.Clear ();
        this.sidebar.Clear ();

        this.measureTool.SetActive (false);
    }

    OnModelLoaded (importResult, threeObject)
    {
        this.model = importResult.model;
        this.parameters.fileNameDiv.innerHTML = importResult.mainFile;
        this.viewer.SetMainObject (threeObject);
        this.viewer.SetUpVector (Direction.Y, false);
        this.navigator.FillTree (importResult);
        this.UpdateSidebar ();
        this.FitModelToWindow (true);
    }

    OnModelClicked (button, mouseCoordinates)
    {
        if (button !== 1) {
            return;
        }

        if (this.measureTool.IsActive ()) {
            this.measureTool.Click (mouseCoordinates);
            return;
        }

        let meshUserData = this.viewer.GetMeshUserDataUnderMouse (mouseCoordinates);
        if (meshUserData === null) {
            this.navigator.SetSelection (null);
        } else {
            this.navigator.SetSelection (new Selection (SelectionType.Mesh, meshUserData.originalMeshId));
        }
    }

    OnModelMouseMoved (mouseCoordinates)
    {
        if (this.measureTool.IsActive ()) {
            this.measureTool.MouseMove (mouseCoordinates);
        }
    }

    OnModelContextMenu (globalMouseCoordinates, mouseCoordinates)
    {
        let meshUserData = this.viewer.GetMeshUserDataUnderMouse (mouseCoordinates);
        let items = [];
        if (meshUserData === null) {
            items.push ({
                name: '使模型适合窗口',
                icon : 'fit',
                onClick : () => {
                    this.FitModelToWindow (false);
                }
            });
            if (this.navigator.HasHiddenMesh ()) {
                items.push ({
                    name: '显示所有网格',
                    icon : 'visible',
                    onClick : () => {
                        this.navigator.ShowAllMeshes (true);
                    }
                });
            }
        } else {
            items.push ({
                name: '隐藏网格',
                icon : 'hidden',
                onClick : () => {
                    this.navigator.ToggleMeshVisibility (meshUserData.originalMeshId);
                }
            });
            items.push ({
                name: '使网格适合窗口',
                icon : 'fit',
                onClick : () => {
                    this.navigator.FitMeshToWindow (meshUserData.originalMeshId);
                }
            });
            if (this.navigator.MeshItemCount () > 1) {
                let isMeshIsolated = this.navigator.IsMeshIsolated (meshUserData.originalMeshId);
                items.push ({
                    name : isMeshIsolated ? '消除孤立' : '孤立网格',
                    icon : isMeshIsolated ? 'deisolate' : 'isolate',
                    onClick : () => {
                        if (isMeshIsolated) {
                            this.navigator.ShowAllMeshes (true);
                        } else {
                            this.navigator.IsolateMesh (meshUserData.originalMeshId);
                        }
                    }
                });
            }
        }
        ShowListPopup (items, {
            calculatePosition : (contentDiv) => {
                return CalculatePopupPositionToScreen (globalMouseCoordinates, contentDiv);
            },
            onClick : (index) => {
                let clickedItem = items[index];
                clickedItem.onClick ();
            }
        });
    }

    OnHashChange ()
    {
        if (this.hashHandler.HasHash ()) {
            let urls = this.hashHandler.GetModelFilesFromHash ();
            if (urls === null) {
                return;
            }
            TransformFileHostUrls (urls);
            let importSettings = new ImportSettings ();
            importSettings.defaultColor = this.settings.defaultColor;
            let defaultColor = this.hashHandler.GetDefaultColorFromHash ();
            if (defaultColor !== null) {
                importSettings.defaultColor = defaultColor;
            }
            HandleEvent ('model_load_started', 'hash');
            this.LoadModelFromUrlList (urls, importSettings);
        } else {
            this.ClearModel ();
            this.SetUIState (WebsiteUIState.Intro);
        }
    }

    OpenFileBrowserDialog ()
    {
        this.parameters.fileInput.click ();
    }

    FitModelToWindow (onLoad)
    {
        let animation = !onLoad;
        let boundingSphere = this.viewer.GetBoundingSphere ((meshUserData) => {
            return this.navigator.IsMeshVisible (meshUserData.originalMeshId);
        });
        if (onLoad) {
            this.viewer.AdjustClippingPlanesToSphere (boundingSphere);
        }
        this.viewer.FitSphereToWindow (boundingSphere, animation);
    }

    FitMeshToWindow (meshInstanceId)
    {
        let boundingSphere = this.viewer.GetBoundingSphere ((meshUserData) => {
            return meshUserData.originalMeshId.IsEqual (meshInstanceId);
        });
        this.viewer.FitSphereToWindow (boundingSphere, true);
    }

    FitMeshesToWindow (meshInstanceIdSet)
    {
        let meshInstanceIdKeys = new Set ();
        for (let meshInstanceId of meshInstanceIdSet) {
            meshInstanceIdKeys.add (meshInstanceId.GetKey ());
        }
        let boundingSphere = this.viewer.GetBoundingSphere ((meshUserData) => {
            return meshInstanceIdKeys.has (meshUserData.originalMeshId.GetKey ());
        });
        this.viewer.FitSphereToWindow (boundingSphere, true);
    }

    UpdateSidebar ()
    {
        let shadingType = this.viewer.GetShadingType ();
        let isPhysicallyBased = (shadingType === ShadingType.Physical);
        let hasDefaultMaterial = HasDefaultMaterial (this.model);
        this.sidebar.UpdateSettings (isPhysicallyBased, hasDefaultMaterial);
    }

    UpdateMeshesVisibility ()
    {
        this.viewer.SetMeshesVisibility ((meshUserData) => {
            return this.navigator.IsMeshVisible (meshUserData.originalMeshId);
        });
    }

    UpdateMeshesSelection ()
    {
        let selectedMeshId = this.navigator.GetSelectedMeshId ();
        this.viewer.SetMeshesHighlight (this.highlightColor, (meshUserData) => {
            if (selectedMeshId !== null && meshUserData.originalMeshId.IsEqual (selectedMeshId)) {
                return true;
            }
            return false;
        });
    }

    LoadModelFromUrlList (urls, settings)
    {
        this.LoadModel (urls, FileSource.Url, settings);
        this.ClearHashIfNotOnlyUrlList ();
    }

    LoadModelFromFileList (files)
    {
        let importSettings = new ImportSettings ();
        importSettings.defaultColor = this.settings.defaultColor;
        this.LoadModel (files, FileSource.File, importSettings);
        this.ClearHashIfNotOnlyUrlList ();
    }

    LoadModel (files, fileSource, settings)
    {
        this.modelLoaderUI.LoadModel (files, fileSource, settings, {
            onStart : () =>
            {
                this.SetUIState (WebsiteUIState.Loading);
                this.ClearModel ();
            },
            onFinish : (importResult, threeObject) =>
            {
                this.SetUIState (WebsiteUIState.Model);
                this.OnModelLoaded (importResult, threeObject);
                let importedExtension = GetFileExtension (importResult.mainFile);
                HandleEvent ('model_loaded', importedExtension);
            },
            onRender : () =>
            {
                this.viewer.Render ();
            },
            onError : (importError) =>
            {
                this.SetUIState (WebsiteUIState.Intro);
                let extensionStr = null;
                if (importError.mainFile !== null) {
                    extensionStr = GetFileExtension (importError.mainFile);
                } else {
                    let extensions = [];
                    let importer = this.modelLoaderUI.GetImporter ();
                    let fileList = importer.GetFileList ().GetFiles ();
                    for (let i = 0; i < fileList.length; i++) {
                        let extension = fileList[i].extension;
                        extensions.push (extension);
                    }
                    extensionStr = extensions.join (',');
                }
                if (importError.code === ImportErrorCode.NoImportableFile) {
                    HandleEvent ('no_importable_file', extensionStr);
                } else if (importError.code === ImportErrorCode.FailedToLoadFile) {
                    HandleEvent ('failed_to_load_file', extensionStr);
                } else if (importError.code === ImportErrorCode.ImportFailed) {
                    HandleEvent ('import_failed', extensionStr, {
                        error_message : importError.message
                    });
                }
            }
        });
    }

    ClearHashIfNotOnlyUrlList ()
    {
        let importer = this.modelLoaderUI.GetImporter ();
        let isOnlyUrl = importer.GetFileList ().IsOnlyUrlSource ();
        if (!isOnlyUrl && this.hashHandler.HasHash ()) {
            this.hashHandler.SkipNextEventHandler ();
            this.hashHandler.ClearHash ();
        }
    }

    UpdateEdgeDisplay ()
    {
        this.settings.SaveToCookies ();
        this.viewer.SetEdgeSettings (this.settings.showEdges, this.settings.edgeColor, this.settings.edgeThreshold);
    }

    UpdateEnvironmentMap ()
    {
        let envMapPath = 'assets/envmaps/' + this.settings.environmentMapName + '/';
        let envMapTextures = [
            envMapPath + 'posx.jpg',
            envMapPath + 'negx.jpg',
            envMapPath + 'posy.jpg',
            envMapPath + 'negy.jpg',
            envMapPath + 'posz.jpg',
            envMapPath + 'negz.jpg'
        ];
        this.viewer.SetEnvironmentMapSettings (envMapTextures, this.settings.backgroundIsEnvMap);
    }

    SwitchTheme (newThemeId, resetColors)
    {
        this.settings.themeId = newThemeId;
        this.themeHandler.SwitchTheme (this.settings.themeId);
        this.settings.SaveToCookies ();
        if (resetColors) {
            this.viewer.SetBackgroundColor (this.settings.backgroundColor);
            let modelLoader = this.modelLoaderUI.GetModelLoader ();
            if (modelLoader.GetDefaultMaterial () !== null) {
                ReplaceDefaultMaterialColor (this.model, this.settings.defaultColor);
                modelLoader.ReplaceDefaultMaterialColor (this.settings.defaultColor);
            }
        }
    }
    getQueryString(name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        var r = window.location.search.substr(1).match(reg);
        if (r != null) return decodeURI(r[2]);
        return null;
    }
    //手机端显示
    PhoneShow(){

        //选钟菜单变色
        $(function(){
            $("#scroll li").click(function() {
                $(this).siblings('li').removeClass('bottom_selected');// 删除其他标签的样式
                $(this).addClass('bottom_selected');// 添加选中的样式
            });
        });


        //大类
        // $(function(){
        //     //文件
        //     $("#nav-item-opt-1").click(function(){
        //         $("#scroll").children().remove();
        //         var list =JSON.parse( window.sessionStorage.getItem("FileItems"));
        //         if (list != null) {
        //              //更改元素的class
        //             $("#bottom_body").removeClass("bottom_body");
        //             $("#bottom_body").addClass("bottom_body_brk")
        //             //添加元素
        //             for(var i = 0;i<list.length;i++){
        //                 $("#scroll").append("<li id=li"+i+">"+ list[i] +"</li>");	//在ul标签上动态添加li标签
        //                 $("#li"+i).attr("class",'liItem_brk');		//为li标签添加class属性
        //             }
        //         }

        //     });
        //     //材质
        //     $("#nav-item-opt-2").click(function(){
        //         $("#scroll").children().remove();
        //         var list =JSON.parse( window.sessionStorage.getItem("MaterialItems"));
        //         if (list!=null) {
        //             $("#bottom_body").removeClass("bottom_body");
        //             $("#bottom_body").addClass("bottom_body_brk")
        //             //添加元素
        //             for(var i = 0;i<list.length;i++){
        //                 $("#scroll").append("<li id=li"+i+">"+ list[i] +"</li>");	//在ul标签上动态添加li标签
        //                 $("#li"+i).attr("class",'liItem_brk');		//为li标签添加class属性
        //             }
        //         }

        //     });
        //     //网格
        //     $("#nav-item-opt-3").click(function(){
        //         $("#scroll").children().remove();
        //         var list =JSON.parse( window.sessionStorage.getItem("GridItems"));
        //         var listName =JSON.parse( window.sessionStorage.getItem("GridNameItems"));
        //         var listTemp = list.map((ele,i)=>{return{...ele,eleName:listName[i]}});
        //         if (list!=null && listName!= null) {

        //             $("#bottom_body").removeClass("bottom_body_brk");
        //             $("#bottom_body").addClass("bottom_body")
        //             //添加元素
        //             for(let i = 0;i<listTemp.length;i++){
        //                 var name = listTemp[i].eleName.length >= 10? listTemp[i].eleName.substring(0,10) + "...":listTemp[i].eleName;
        //                 $("#scroll").append(`<li id=li${i}>
        //                         <div class="cards">
        //                             <div class="cards_item_name"  id="cards_item_name${i}" >${name}</div>
        //                             <div class="cards_item_position" id="cards_item_position${i}">
        //                                 <img src="${WebPath}/assets/images/enlarge.png" style="margin-left:7px" width="22px" />
        //                             </div>
        //                             <div class="cards_item_color" id="cards_item_color${i}" style="background-color:rgb(${listTemp[i].color.r}, ${listTemp[i].color.g}, ${listTemp[i].color.b});"></div>
        //                             <div class="cards_item_show" id="cards_item_show${i}">
        //                                 <img id="cards_item_show_icon${i}" src="${WebPath}/assets/images/show.png" style="margin-left:7px" width="22px" />
        //                             </div>
        //                         </div>
        //                     </li>`);	//在ul标签上动态添加li标签
        //                 $(`#li${i}`).attr("class",'liItem');		//为li标签添加class属性
        //                 //对应 模型选中变色
        //                 $(`#cards_item_name${i}`).unbind("click").bind("click",function(){
        //                     $(`#${EscapeJquery(listTemp[i].eleName)}`).click();
        //                 });
        //                 //对应 模型的定位
        //                 $(`#cards_item_position${i}`).unbind("click").bind("click",function(){
        //                     $(`#${EscapeJquery(listTemp[i].eleName)}`).prev(".ov_tree_item_button_container").children(":first").click();
        //                 });
        //                 //对应 模型的隐藏显示
        //                 $(`#cards_item_show${i}`).unbind("click").bind("click",function(){
        //                     if ( $(`#cards_item_show_icon${i}`).attr("src") == `${WebPath}/assets/images/show.png`) {
        //                         $(`#cards_item_show_icon${i}`).attr("src",`${WebPath}/assets/images/hide.png`);
        //                     }else{
        //                         $(`#cards_item_show_icon${i}`).attr("src",`${WebPath}/assets/images/show.png`);
        //                     }
        //                     $(`#${EscapeJquery(listTemp[i].eleName)}`).prev(".ov_tree_item_button_container").children(":last-child").click();
        //                 });
        //             }
        //         }
        //     });

        //     $("#test").click(function(){
        //        $(`#PAROTID_RT`).click();
        //     });
        // })

        //默认触发
        // $("#nav-item-opt-1").click();
                $("#scroll").children().remove();
                var list =JSON.parse( window.sessionStorage.getItem("GridItems"));
                var listName =JSON.parse( window.sessionStorage.getItem("GridNameItems"));

                if (list!=null && listName!= null) {
                    var listTemp = [];
                     list.forEach((ele,i)=>{
                        if (listName[i] != null) {
                            listTemp.push({ ...ele,eleName:listName[i]});
                        }
                    });

                    $("#bottom_body").removeClass("bottom_body_brk");
                    $("#bottom_body").addClass("bottom_body")
                    //添加元素
                    for(let i = 0;i<listTemp.length;i++){
                        var name = listTemp[i].eleName != null? listTemp[i].eleName.length >= 10? listTemp[i].eleName.substring(0,10) + "...":listTemp[i].eleName:"";
                        $("#scroll").append(`<li id=li${i}>
                                <div class="cards">
                                    <div class="cards_item_name"  id="cards_item_name${i}" >${name}</div>
                                    <div class="cards_item_position" id="cards_item_position${i}">
                                        <img src="${WebPath}/assets/images/enlarge.png" style="margin-left:7px" width="22px" />
                                    </div>
                                    <div class="cards_item_color" id="cards_item_color${i}" style="background-color:rgb(${listTemp[i].color.r}, ${listTemp[i].color.g}, ${listTemp[i].color.b});"></div>
                                    <div class="cards_item_show" id="cards_item_show${i}">
                                        <img id="cards_item_show_icon${i}" src="${WebPath}/assets/images/show.png" style="margin-left:7px" width="22px" />
                                    </div>
                                </div>
                            </li>`);	//在ul标签上动态添加li标签
                        $(`#li${i}`).attr("class",'liItem');		//为li标签添加class属性
                        //对应 模型选中变色
                        $(`#cards_item_name${i}`).unbind("click").bind("click",function(){
                            $(`#${EscapeJquery(listTemp[i].eleName)}`).click();
                        });
                        //对应 模型的定位
                        $(`#cards_item_position${i}`).unbind("click").bind("click",function(){
                            $(`#${EscapeJquery(listTemp[i].eleName)}`).prev(".ov_tree_item_button_container").children(":first").click();
                        });
                        //对应 模型的隐藏显示
                        $(`#cards_item_show${i}`).unbind("click").bind("click",function(){
                            if ( $(`#cards_item_show_icon${i}`).attr("src") == `${WebPath}/assets/images/show.png`) {
                                $(`#cards_item_show_icon${i}`).attr("src",`${WebPath}/assets/images/hide.png`);
                            }else{
                                $(`#cards_item_show_icon${i}`).attr("src",`${WebPath}/assets/images/show.png`);
                            }
                            $(`#${EscapeJquery(listTemp[i].eleName)}`).prev(".ov_tree_item_button_container").children(":last-child").click();
                        });
                    }
                }
    }

    //获取患者列表
    PatientList(){
        let userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
        const form = {
            currentPage: 1,
            pageSize: 15,
            patientCode: "",
            patientName: "",
            organizationId:userInfo.belong.id
        }
        axios.post(`${ApiPath}/api/Viewer3D/GetFileModel`,form).then(res => {
            res.data.data.s3Ddemos.forEach(element => {
                $("#patientList").append(`
                    <div class="patient-item" data-id="${element.id}" style="font-size: 16px;display: flex;justify-content: center;">
                        <div class="patient_list_columns">${element.patientName}</div>
                        <div class="patient_list_columns">${element.patientCode}</div>
                        <div class="patient_list_columns">${this.dateFormatByFmt("YYYY-mm-dd", element.createDate)}</div>
                    </div>
                `);
            });

        })
    }


     dateFormatByFmt(fmt, time) {
        if(time == '' || time == null) return
        let date = new Date(time)
        let ret;
        const opt = {
          "Y+": date.getFullYear().toString(), // 年
          "m+": (date.getMonth() + 1).toString(), // 月
          "d+": date.getDate().toString(), // 日
          "H+": date.getHours().toString(), // 时
          "M+": date.getMinutes().toString(), // 分
          "S+": date.getSeconds().toString(), // 秒
          // 有其他格式化字符需求可以继续添加，必须转化成字符串
        };
        for (let k in opt) {
          ret = new RegExp("(" + k + ")").exec(fmt);
          if (ret) {
            fmt = fmt.replace(
              ret[1],
              ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
            );
          }
        }
        return fmt;
      }
    //菜单栏
    TabMenu(){
        var _this = this;
            //选钟菜单变色
            $(function(){
                $("#scroll li").click(function() {
                    $(this).siblings('li').removeClass('bottom_selected');// 删除其他标签的样式
                    $(this).addClass('bottom_selected');// 添加选中的样式
                });
            });
            $(function(){
                $(".tabbox li").click(function () {
                        //获取点击的元素给其添加样式，讲其兄弟元素的样式移除
                        $(this).addClass("active").siblings().removeClass("active");
                        //获取选中元素的下标
                        var index = $(this).index();
                        $(this).parent().siblings().children().eq(index).addClass("active")
                            .siblings().removeClass("active");

                        //获取选中的标题
                        var tabName = $(this).text();
                        switch (tabName) {
                            case '首页':
                                window.sessionStorage.setItem("Active","Home");
                                $('#CTBtn').hide();
                                $('#main,#toolbar').hide();
                                $('#patientList').hide();
                                $("#commentBtn").hide();
                                $('#intro').show();
                                break;
                            case '计划':
                                window.sessionStorage.setItem("Active","Plan");
                                _this.GetModelByPatientFileType2("Plan",PlanModel.name);
                                break;
                            case '3D':

                                window.sessionStorage.setItem("Active","Model");
                                $('#main,#toolbar').show();
                                $('#CTBtn').hide();
                                $('#patientList').hide();
                                $("#commentBtn").show();
                                if (window.sessionStorage.getItem("GetDataByPlanModel") == JSON.stringify(PlanModel) && window.sessionStorage.getItem("GetDataByPlanModelIsGetModel") == "true") {
                                    return
                                }
                                 _this.GetModelByPatientFileType2("Model",PlanModel.name);
                                break;
                            case '影像':

                                window.sessionStorage.setItem("Active","Image");
                                $('#main,#toolbar').hide();
                                $('#patientList').hide();
                                $('#CTBtn').show();
                                $("#commentBtn").hide();

                                if (window.sessionStorage.getItem("GetDataByPlanModel") == JSON.stringify(PlanModel) && window.sessionStorage.getItem("GetDataByPlanModelIsGetImage") == "true") {
                                    return
                                }
                                if (_this.getQueryString('IsReadServer') == "true") {
                                    switch (_this.getQueryString('Type')) {
                                        case "2":
                                        case "3":
                                            _this.GetModelByPatientFileType2("Image",PlanModel.name);
                                            break;
                                        default:
                                            break;
                                    }
                                }

                                break;
                            case 'PDF':
                                // $('#main,#toolbar').hide();
                                // $('#CTBtn').show();
                                if (_this.getQueryString('IsReadServer') == "true") {
                                    window.sessionStorage.setItem("Active","Report");
                                    switch (_this.getQueryString('Type')) {
                                        case "2":
                                        case "3":
                                            _this.GetModelByPatientFileType2("Report",PlanModel.name);
                                            break;
                                        default:
                                            break;
                                    }
                                }
                                break;
                            case 'DVH':
                                window.sessionStorage.setItem("Active","DVH");
                                $('#main,#toolbar').hide();
                                $('#patientList').hide();
                                $('#CTBtn').hide();
                                $("#intro").hide();
                                $("#editPassword").hide();
                                $("#disclaimer").hide();
                                $("#DVHView").show();
                                 _this.GetModelByPatientFileType2("DVH",PlanModel.name);
                                break;
                            default:
                                break;
                        }
                    });
            })


    }
    //记录日志
    SystemLog(tabName){
        let userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
        let temp = {
            type:"3",
            userId:userInfo.id,
            userAccount:userInfo.account,
            userName:userInfo.name,
            sourceURL:"医工交互",
            operateModule:tabName,
            operateContent:this.getQueryString('Id'),
            remarks:PlanModel.patientName + " --> " + PlanModel.name
        }
        axios.post(`${ApiPath}/api/System/CreateSystemLog`,temp).then(res => {
        })
    }

    //初始
    InitWantShowFile(){
        window.sessionStorage.setItem("Active","Plan")
        if (this.getQueryString('IsReadServer') == "true") {

            switch (this.getQueryString('Type')) {
                case "1":
                    this.GetModelByPatientFileType1();
                    break;
                case "2":
                    this.GetModelByPatientFileType2("Model",PlanModel.name);
                    break;
                case "3":
                    this.GetModelByPatientFileType2("Plan",PlanModel.name);
                    break;
                default:
                    break;
            }
        }
    }

    //Type1
    GetModelByPatientFileType1(){
        let progressDialog = null;
        progressDialog = new ProgressDialog ();
        this.SetUIState (WebsiteUIState.Loading);
        this.ClearModel ();
        progressDialog.Init ('正在导入模型，请稍等。。');
        progressDialog.Open ();
        axios.get(`${ApiPath}/api/Viewer3D/GetFileModelById?Id=${this.getQueryString('Id')}`, {
            responseType:"blob"
        }).then(res => {
               if (res.headers.extensionname == '.zip' || res.headers.extensionname == '.rar') {
                    let blob = new Blob([res.data], {type: `application/zip;charset=utf-8`});
                    var file = new window.File([blob], res.headers.filename, {type: 'application/zip'});
                    this.LoadModelFromFileList ([file]);
                    progressDialog.Close ();
               }else{
                    let blob = new Blob([res.data]);
                    var file = new window.File([blob], res.headers.filename, {type: 'blob'});
                    this.LoadModelFromFileList ([file]);
                    progressDialog.Close();
               }

        })

    }
    //Type2
    GetModelByPatientFileType2(Type,Plan){
        axios.post(`${ApiPath}/api/Viewer3D/GetPatientByModel?Id=${this.getQueryString('Id')}&Type=${Type}&Plan=${Plan}`).then(res => {
            let progressDialog = null;
            progressDialog = new ProgressDialog ();
            //如果只有一个文件
            if (res.data.data.length == 1) {
                if (Type=="Model") {
                    this.SetUIState (WebsiteUIState.Loading);
                    this.ClearModel ();
                }
                if (window.sessionStorage.getItem("GetDataByPlanModel") != JSON.stringify(PlanModel) && window.sessionStorage.getItem("GetDataByPlanModelIsGetModel") != "true") {
                    progressDialog.Init ('正在导入，请稍等。。');
                    progressDialog.Open ();
                }


            }

            const model = res.data.data.map(ele=>{
                return ele.name
            })
            let name = Type=="Model"?"模型":Type=="Image"?"CT":Type=="Plan"?"计划":Type=="Report"?"报告":Type=="DVH"?"DVH":"首页";

            if (res.data.data.length == 1) {
                switch (Type) {
                    case "Plan":
                        PlanModel = { id:res.data.data[0].id, name:res.data.data[0].name,patientName: res.data.data[0].patientName,patientCode: res.data.data[0].patientCode};
                        window.sessionStorage.setItem("PlanModel",JSON.stringify(PlanModel));
                        $("#header1").hide();
                        $("#header2").show();
                        $("#header2").text(PlanModel.patientName +" — " +PlanModel.patientCode );
                        if (window.sessionStorage.getItem("Active") == null) {
                            $("#tabNav").children().eq(2).click();
                            return;
                        }
                        switch (window.sessionStorage.getItem("Active")){
                            case "Home":
                                $("#tabNav").children().eq(0).click();
                                break;
                            case "Plan":
                                $("#tabNav").children().eq(2).click();
                                break;
                            case "Model":
                                $("#tabNav").children().eq(2).click();
                                break;
                            case "Image":
                                $("#tabNav").children().eq(3).click();
                                break;
                            case "DVH":
                                $("#tabNav").children().eq(4).click();
                                break;
                            default:
                                break;
                        }
                        break;
                    case "Model":
                        this.GetModelByFileIdModelType2(res.data.data[0].id)
                        break;
                    case "Report":
                        this.GetModelByFileIdPDFType2(res.data.data[0].id)
                        break;
                    case "Image":
                        this.GetModelByFileIdImageType2(res.data.data[0].id)
                        break;
                    case "DVH":
                        this.GetModelByFileIdDVHType2(res.data.data[0].id)
                        break;
                    default:
                        break;
                }
            }else{
                this.modalDialog = this.ShowFileSelectorDialog (model,name, (index) => {

                    switch (Type) {
                        case "Plan":
                            PlanModel = { id:res.data.data[index].id, name:res.data.data[index].name,patientName:res.data.data[index].patientName,patientCode: res.data.data[index].patientCode};
                            if (window.sessionStorage.getItem("GetDataByPlanModel") != JSON.stringify(PlanModel) ) {
                                window.sessionStorage.setItem("GetDataByPlanModelIsGetModel",false);
                                window.sessionStorage.setItem("GetDataByPlanModelIsGetImage",false);
                            }

                            window.sessionStorage.setItem("PlanModel",JSON.stringify(PlanModel));
                            $("#header1").hide();
                            $("#header2").show();
                            $("#header2").text(PlanModel.patientName +" — " +PlanModel.patientCode );
                            switch (window.sessionStorage.getItem("Active")){
                                case "Home":
                                    $("#tabNav").children().eq(0).click();
                                    break;
                                case "Plan":
                                    $("#tabNav").children().eq(2).click();
                                    break;
                                case "Model":
                                    $("#tabNav").children().eq(2).click();
                                    break;
                                case "Image":
                                    $("#tabNav").children().eq(3).click();
                                    break;
                                case "DVH":
                                    $("#tabNav").children().eq(4).click();
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Model":
                            this.GetModelByFileIdModelType2(res.data.data[index].id)
                            break;
                        case "Report":
                            this.GetModelByFileIdPDFType2(res.data.data[index].id)
                            break;
                        case "Image":
                            this.GetModelByFileIdImageType2(res.data.data[index].id)
                            break;
                        case "DVH":
                            this.GetModelByFileIdDVHType2(res.data.data[index].id)
                            break;
                        default:
                            break;
                    }

                });
            }
        })
    }
    //Type2 Model
    GetModelByFileIdModelType2(Id){

        window.sessionStorage.setItem("GetDataByPlanModel",JSON.stringify(PlanModel));
        window.sessionStorage.setItem("GetDataByPlanModelIsGetModel","true");
        this.SystemLog("3D模型");
        axios.get(`${ApiPath}/api/Viewer3D/GetFileModelById?Id=${Id}`, {
            responseType:"blob"
        }).then(res => {
               if (res.headers.extensionname == '.zip' || res.headers.extensionname == '.rar') {
                    let blob = new Blob([res.data], {type: `application/zip;charset=utf-8`});
                    var file = new window.File([blob], res.headers.filename, {type: 'application/zip'});
                    this.LoadModelFromFileList ([file]);

               }else{
                    let blob = new Blob([res.data]);
                    var file = new window.File([blob], res.headers.filename, {type: 'blob'});
                    console.log([file]);
                    this.LoadModelFromFileList ([file]);
               }

        })

    }

    //Type2 PDF
    GetModelByFileIdPDFType2(Id){
        this.SystemLog("PDF报告");
        let progressDialog = null;
        progressDialog = new ProgressDialog ();
        progressDialog.Init ('正在下载，请稍后。。。');
        progressDialog.Open ();
        axios.get(`${ApiPath}/api/Viewer3D/GetFileModelById?Id=${Id}`, {
            // responseType:"blob"
        }).then(res => {
            // console.log(res.data);
            // this.downloadFile(res.data,'',`报告.pdf`);
            var tempwindow=window.open('_blank');
            tempwindow.location= `${WebPath}/${res.data.data}` ;
            progressDialog.Close();
            //    window.open( window. URL.createObjectURL(res.data));
        })

    }

    //Type2 Image
    GetModelByFileIdImageType2(Id){
        window.sessionStorage.setItem("GetDataByPlanModel",JSON.stringify(PlanModel));
        window.sessionStorage.setItem("GetDataByPlanModelIsGetImage","true");
        this.SystemLog("影像资料");
        let progressDialog = null;
        progressDialog = new ProgressDialog ();
        progressDialog.Init ('正在下载，请稍后。。。');
        progressDialog.Open ();
        axios.get(`${ApiPath}/api/Viewer3D/GetFileModelById?Id=${Id}`, {
            responseType:"blob"
        }).then(res => {
            let blob = new Blob([res.data], {type: `application/zip;charset=utf-8`});
            var file = new window.File([blob], res.headers.filename, {type: 'application/zip'});

            function handleFile(f) {
                JSZip.loadAsync(f)                                   // 1) read the Blob
                    .then(function(zip) {
                        $("#CT_model_img").empty();
                        zip.forEach(async function (relativePath, zipEntry) {  // 2) print entries
                            var base64Img = await zipEntry.async('base64');
                            const suffixName = zipEntry.name.split('.')[1];
                            var img =`data:image/${suffixName};base64,${base64Img}`;
                            $("#CT_model_img").append(`
                                <li id=${zipEntry.name.split('.')[0].replace('image','')}><img  src=${img} alt=""></li>
                            `);
                        });
                    }, function (e) {
                        $result.append($("<div>", {
                            "class" : "alert alert-danger",
                            text : "Error reading " + f.name + ": " + e.message
                        }));
                    });

            }

            handleFile(file);
            setTimeout(() => {
                var oUl1 = document.getElementById("CT_model_img");
                var aLi=oUl1.getElementsByTagName('li');
                var arr=[];
                for(var i=0;i<aLi.length;i++)  //把li用数组表示
                {
                    arr[i]=aLi[i];
                }
                arr.sort(function(li1,li2){
                    var n1=parseInt(li1.id);
                    var n2=parseInt(li2.id);
                    return n1-n2;  //升序排列
                })
                for(var i=0;i<arr.length;i++){
                    oUl1.appendChild(arr[i]);   //排序之后再写入
                }
                progressDialog.Close();
            }, 8000);
        })
    }

    GetModelByFileIdDVHType2(Id){
        window.sessionStorage.setItem("GetDataByPlanModel",JSON.stringify(PlanModel));
        window.sessionStorage.setItem("GetDataByPlanModelIsGetModel","true");
        this.SystemLog("DVH");
        let progressDialog = null;
        progressDialog = new ProgressDialog ();
        progressDialog.Init ('正在下载，请稍后。。。');
        progressDialog.Open ();
        axios.get(`${ApiPath}/api/Viewer3D/GetFileModelById?Id=${Id}`).then(res => {
            $('#DVHImg').attr('src', `${WebPath}/${res.data.data}`);
            progressDialog.Close();
        })
    }

    downloadFile(data, type, fileName) {
        let blob = new Blob([data], {type: `application/${type};charset=utf-8`});
        // 获取heads中的filename文件名
        let downloadElement = document.createElement('a');
        // 创建下载的链接
        let href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        // 下载后文件名
        downloadElement.download = fileName;
        document.body.appendChild(downloadElement);
        // 点击下载
        downloadElement.click();
        // 下载完成移除元素
        document.body.removeChild(downloadElement);
        // 释放掉blob对象
        window.URL.revokeObjectURL(href);
    }

    ShowFileSelectorDialog (fileNames,name, onSelect)
    {

        let dialog = new ButtonDialog ();
        let contentDiv = dialog.Init (`选择${name}`, [
            {
                name : 'Cancel',
                subClass : 'outline',
                onClick () {
                    dialog.SetCloseHandler (null);
                    dialog.Close ();
                }
            }
        ]);

        dialog.SetCloseHandler (() => {

        });

        let text = `找到多个可导入${name}。从下面的列表中选择要导入的${name}。`;
        AddDiv (contentDiv, 'ov_dialog_message', text);

        let fileListSection = AddDiv (contentDiv, 'ov_dialog_section');
        let fileList = AddDiv (fileListSection, 'ov_dialog_import_file_list ov_thin_scrollbar');

        for (let i = 0; i < fileNames.length; i++) {
            let fileName = fileNames[i];
            let fileLink = AddDiv (fileList, 'ov_dialog_file_link');
            AddSvgIconElement (fileLink, 'meshes', 'ov_file_link_img');
            AddDiv (fileLink, 'ov_dialog_file_link_text', fileName);
            fileLink.addEventListener ('click', () => {
                dialog.SetCloseHandler (null);
                dialog.Close ();
                onSelect (i);
            });
        }
        dialog.Open ();
        return dialog;
    }

    InitViewer ()
    {

        let canvas = AddDomElement (this.parameters.viewerDiv, 'canvas');
        this.viewer.Init (canvas);
        this.viewer.SetEdgeSettings (this.settings.showEdges, this.settings.edgeColor, this.settings.edgeThreshold);
        this.viewer.SetBackgroundColor (this.settings.backgroundColor);
        this.UpdateEnvironmentMap ();
    }

    InitToolbar ()
    {

        function AddButton (toolbar, imageName, imageTitle, classNames, onClick)
        {
            let button = toolbar.AddImageButton (imageName, imageTitle, () => {
                onClick ();
            });
            for (let className of classNames) {
                button.AddClass (className);
            }
            return button;
        }

        function AddPushButton (toolbar, imageName, imageTitle, classNames, onClick)
        {
            let button = toolbar.AddImagePushButton (imageName, imageTitle, false, (isSelected) => {
                onClick (isSelected);
            });
            for (let className of classNames) {
                button.AddClass (className);
            }
            return button;
        }

        function AddRadioButton (toolbar, imageNames, imageTitles, selectedIndex, classNames, onClick)
        {
            let imageData = [];
            for (let i = 0; i < imageNames.length; i++) {
                let imageName = imageNames[i];
                let imageTitle = imageTitles[i];
                imageData.push ({
                    image : imageName,
                    title : imageTitle
                });
            }
            let buttons = toolbar.AddImageRadioButton (imageData, selectedIndex, (buttonIndex) => {
                onClick (buttonIndex);
            });
            for (let className of classNames) {
                for (let button of buttons) {
                    button.AddClass (className);
                }
            }
        }

        function AddSeparator (toolbar, classNames)
        {
            let separator = toolbar.AddSeparator ();
            if (classNames !== null) {
                for (let className of classNames) {
                    separator.classList.add (className);
                }
            }
        }

        let importer = this.modelLoaderUI.GetImporter ();

        AddButton (this.toolbar, 'open', '模型列表', [], () => {
            // this.OpenFileBrowserDialog ();
        });
        // AddButton (this.toolbar, 'open_url', '打开url模型', [], () => {
        //     ShowOpenUrlDialog ((urls) => {
        //         if (urls.length > 0) {
        //             this.hashHandler.SetModelFilesToHash (urls);
        //         }
        //     });
        // });
        AddSeparator (this.toolbar, ['only_on_model']);
        AddButton(this.toolbar, 'fit', '使模型适合窗口', ['only_on_model'], () => {
            this.FitModelToWindow (false);
        });
        AddButton(this.toolbar, 'up_y', '将Y轴设置为向上矢量', ['only_on_model'], () => {
            this.viewer.SetUpVector (Direction.Y, true);
        });
        AddButton(this.toolbar, 'up_z', '将Z轴设置为向上矢量', ['only_on_model'], () => {
            this.viewer.SetUpVector (Direction.Z, true);
        });
        AddButton(this.toolbar, 'flip', '翻转向上矢量', ['only_on_model'], () => {
            this.viewer.FlipUpVector ();
        });
        // AddSeparator (this.toolbar, ['only_on_model']);
        // AddRadioButton(this.toolbar, ['fix_up_on', 'fix_up_off'], ['固定向上矢量固定向上矢量', '自由轨道'], 0, ['only_on_model'], (buttonIndex) => {
        //     if (buttonIndex === 0) {
        //         this.viewer.SetFixUpVector (true);
        //     } else if (buttonIndex === 1) {
        //         this.viewer.SetFixUpVector (false);
        //     }
        // });
        this.viewer.SetFixUpVector (false);
        // AddSeparator (this.toolbar, ['only_full_width', 'only_on_model']);
        let measureToolButton = AddPushButton (this.toolbar, 'measure', '测量', ['only_full_width', 'only_on_model'], (isSelected) => {
            HandleEvent ('measure_tool_activated', isSelected ? 'on' : 'off');
            this.navigator.SetSelection (null);
            this.measureTool.SetActive (isSelected);
        });
        this.measureTool.SetButton (measureToolButton);
        AddSeparator (this.toolbar, ['only_full_width', 'only_on_model']);
        AddButton(this.toolbar, 'snapshot', '创建快照', ['only_full_width', 'only_on_model'], () => {
            ShowSnapshotDialog (this.viewer);
        });
        // AddButton(this.toolbar, 'export', '导出模型', ['only_full_width', 'only_on_model'], () => {
        //     ShowExportDialog (this.model, this.viewer, {
        //         isMeshVisible : (meshInstanceId) => {
        //             return this.navigator.IsMeshVisible (meshInstanceId);
        //         }
        //     });
        // });
        // AddButton (this.toolbar, 'share', '查看CT', [ 'only_on_model'], () => {
        //     // ShowSharingDialog (importer.GetFileList (), this.settings, this.viewer.GetCamera ());
        //     if (this.getQueryString('IsReadServer') == "true") {
        //         switch (this.getQueryString('Type')) {
        //             case "2":
        //                 if ( $("#CTBtn").css("display") == "none") {
        //                     this.GetModelByPatientFileType2("Image");
        //                 }
        //                 $("#CTBtn").toggle();

        //                 break;
        //             default:
        //                 break;
        //         }
        //     }
        // });
        // AddButton(this.toolbar, 'export', '查看报告', ['only_on_model'], () => {
        //     //原先是导出模型
        //     // ShowExportDialog (this.model, this.viewer, {
        //     //     isMeshVisible : (meshInstanceId) => {
        //     //         return this.navigator.IsMeshVisible (meshInstanceId);
        //     //     }
        //     // });
        //     if (this.getQueryString('IsReadServer') == "true") {

        //         switch (this.getQueryString('Type')) {
        //             case "2":
        //                 this.GetModelByPatientFileType2("Report");
        //                 break;
        //             default:
        //                 break;
        //         }
        //     }
        // });
        this.parameters.fileInput.addEventListener ('change', (ev) => {
            if (ev.target.files.length > 0) {
                HandleEvent ('model_load_started', 'open_file');
                this.LoadModelFromFileList (ev.target.files);
            }
        });
    }


    getQueryString(name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        var r = window.location.search.substr(1).match(reg);
        if (r != null) return decodeURI(r[2]);
        return null;
    }


    InitDragAndDrop ()
    {
        window.addEventListener ('dragstart', (ev) => {
            ev.preventDefault ();
        }, false);

        window.addEventListener ('dragover', (ev) => {
            ev.stopPropagation ();
            ev.preventDefault ();
            ev.dataTransfer.dropEffect = 'copy';
        }, false);

        window.addEventListener ('drop', (ev) => {
            ev.stopPropagation ();
            ev.preventDefault ();
            GetFilesFromDataTransfer (ev.dataTransfer, (files) => {
                if (files.length > 0) {
                    HandleEvent ('model_load_started', 'drop');
                    this.LoadModelFromFileList (files);
                }
            });
        }, false);

    }

    InitSidebar ()
    {

        this.sidebar.Init ({
            onEnvironmentMapChange : () => {
                this.settings.SaveToCookies ();
                this.UpdateEnvironmentMap ();
            },
            onBackgroundColorChange : () => {
                this.settings.SaveToCookies ();
                this.viewer.SetBackgroundColor (this.settings.backgroundColor);
                if (this.measureTool.IsActive ()) {
                    this.measureTool.UpdatePanel ();
                }
            },
            onDefaultColorChange : () => {
                this.settings.SaveToCookies ();
                let modelLoader = this.modelLoaderUI.GetModelLoader ();
                if (modelLoader.GetDefaultMaterial () !== null) {
                    ReplaceDefaultMaterialColor (this.model, this.settings.defaultColor);
                    modelLoader.ReplaceDefaultMaterialColor (this.settings.defaultColor);
                }
                this.viewer.Render ();
            },
            onEdgeDisplayChange : () => {
                HandleEvent ('edge_display_changed', this.settings.showEdges ? 'on' : 'off');
                this.UpdateEdgeDisplay ();
            },
            onThemeChange : () => {
                HandleEvent ('theme_changed', this.settings.themeId === Theme.Light ? 'light' : 'dark');
                this.SwitchTheme (this.settings.themeId, true);
            },
            onResize : () => {
                this.Resize ();
            },
            onShowHidePanels : (show) => {
                CookieSetBoolVal ('ov_show_sidebar', show);
            }
        });
    }

    InitNavigator ()
    {

        function GetMeshUserData (viewer, meshInstanceId)
        {
            let userData = null;
            viewer.EnumerateMeshesUserData ((meshUserData) => {
                if (meshUserData.originalMeshId.IsEqual (meshInstanceId)) {
                    userData = meshUserData;
                }
            });
            return userData;
        }

        function GetMeshesForMaterial (viewer, model, materialIndex)
        {
            let usedByMeshes = [];
            viewer.EnumerateMeshesUserData ((meshUserData) => {
                if (materialIndex === null || meshUserData.originalMaterials.indexOf (materialIndex) !== -1) {
                    const mesh = model.GetMesh (meshUserData.originalMeshId.meshIndex);
                    usedByMeshes.push ({
                        meshId : meshUserData.originalMeshId,
                        name : mesh.GetName ()
                    });
                }
            });
            return usedByMeshes;
        }

        function GetMaterialReferenceInfo (model, materialIndex)
        {
            const material = model.GetMaterial (materialIndex);
            return {
                index : materialIndex,
                name : material.name,
                color : material.color.Clone ()
            };
        }

        function GetMaterialsForMesh (viewer, model, meshInstanceId)
        {
            let usedMaterials = [];
            if (meshInstanceId === null) {
                for (let materialIndex = 0; materialIndex < model.MaterialCount (); materialIndex++) {
                    usedMaterials.push (GetMaterialReferenceInfo (model, materialIndex));
                }
            } else {
                let userData = GetMeshUserData (viewer, meshInstanceId);
                for (let i = 0; i < userData.originalMaterials.length; i++) {
                    const materialIndex = userData.originalMaterials[i];
                    usedMaterials.push (GetMaterialReferenceInfo (model, materialIndex));
                }
            }
            usedMaterials.sort ((a, b) => {
                return a.index - b.index;
            });
            return usedMaterials;
        }

        this.navigator.Init ({
            openFileBrowserDialog : () => {
                this.OpenFileBrowserDialog ();
            },
            updateMeshesVisibility : () => {
                this.UpdateMeshesVisibility ();
            },
            updateMeshesSelection : () => {
                this.UpdateMeshesSelection ();
            },
            fitMeshToWindow : (meshInstanceId) => {
                this.FitMeshToWindow (meshInstanceId);
            },
            fitMeshesToWindow : (meshInstanceIdSet) => {
                this.FitMeshesToWindow (meshInstanceIdSet);
            },
            getMeshesForMaterial : (materialIndex) => {
                return GetMeshesForMaterial (this.viewer, this.model, materialIndex);
            },
            getMaterialsForMesh : (meshInstanceId) => {
                return GetMaterialsForMesh (this.viewer, this.model, meshInstanceId);
            },
            onModelSelected : () => {
                this.sidebar.AddObject3DProperties (this.model);
            },
            onMeshSelected : (meshInstanceId) => {
                let meshInstance = this.model.GetMeshInstance (meshInstanceId);
                this.sidebar.AddObject3DProperties (meshInstance);
            },
            onMaterialSelected : (materialIndex) => {
                this.sidebar.AddMaterialProperties (this.model.GetMaterial (materialIndex));
            },
            onResize : () => {
                this.Resize ();
            },
            onShowHidePanels : (show) => {
                CookieSetBoolVal ('ov_show_navigator', show);
            }
        });
    }

    UpdatePanelsVisibility ()
    {

        let showNavigator = CookieGetBoolVal ('ov_show_navigator', true);
        let showSidebar = CookieGetBoolVal ('ov_show_sidebar', true);
        this.navigator.ShowPanels (showNavigator);
        this.sidebar.ShowPanels (showSidebar);
    }

    InitCookieConsent ()
    {
        let accepted = CookieGetBoolVal ('ov_cookie_consent', false);
        if (accepted) {
            return;
        }

        let text = '本网站使用 Cookie 为您提供更好的用户体验。详情请见<a target="_blank" href="info/cookies.html">Cookies政策</a> 页面.';
        let popupDiv = AddDiv (document.body, 'ov_bottom_floating_panel');
        AddDiv (popupDiv, 'ov_floating_panel_text', text);
        let acceptButton = AddDiv (popupDiv, 'ov_button ov_floating_panel_button', 'Accept');
        acceptButton.addEventListener ('click', () => {
            CookieSetBoolVal ('ov_cookie_consent', true);
            popupDiv.remove ();
        });
    }
}
